import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';

import API from '../api/api.js';
import { AuthContext } from './AuthContextProvider.jsx';

export const DictationSessionContext = React.createContext();

const DictationSessionContextProvider = ({ children }) => {

  /**
   * 当前正在播放的 Session 信息作为全局状态提起
   * {
      "session-id": 2, // only for dictation session
      "session-create-time": "2024-10-27T06:32:21.360163438" // only for dictation session
      "audio-uuid": "c2cff0e3-318c-4c5e-bd4f-e16ab619260e",
      "audio-src": "http://echo-us-west.oss-us-west-1.aliyuncs.com/audio/c2cff0e3-318c-4c5e-bd4f-e16ab619260e.mp3?Expires=1730003541&OSSAccessKeyId=LTAI5tS66b6yK44Hm7Z6HS7q&Signature=DNYmMEWd1betrZarfVy7cjRZJhs%3D",
      "title": "Gettysburg Address",
      "duration": 192,
      "mimeType": "audio/mpeg",
      "access-level": 2,
      "segments-num": 19,
      "cover-img": "https://xxx",
      "last-dictation-pos": 0,
      "sentences": [
        {
          "sentenceId": 92,
          "audioUuid": "c2cff0e3-318c-4c5e-bd4f-e16ab619260e",
          "index": 1,
          "text": "Now listen to two students discussing the letter. ",
          "start": 10, // 单位: ms
          "end": 3760, // 单位: ms
          "attemptText": "Now listen to two students discussing the letter. ", // only for dictation history
          "attemptTime": "2024-10-27T06:32:21.360163438" // only for dictation history
        },
      ],
   * }
   */
  const [dictationSession, setDictationSession] = useState(null);
  const [isDictationSessionLoading, setIsDictationSessionLoading] = useState(true);
  const [isDictationSessionFailed, setIsDictationSessionFailed] = useState(false);
  const [currentDictationIndex, setCurrentDictationIndex] = useState(0);
  const [isDictationAnswerDisplayed, setIsDictationAnswerDisplayed] = useState(false);

  // 发起一个新的听写 session
  const startDictationSession = (audioUuid) => {
    setIsDictationSessionLoading(true);
    API
      .post("/dictation", null, {
        params: {
          'audio-uuid': audioUuid,
        }
      })
      .then(onStartSessionSuccess)
      .catch(onStartSessionFail);
  }

  // 游客可听写案例音频
  const startGuestDictationSession = async () => {
    setIsDictationSessionLoading(true);
    try {
      const response = await API.get("/dictation/guest");
      onStartSessionSuccess(response);
    } catch (error) {
      onStartSessionFail(error);
    }
  }

  // 回到用户上一次听写的地方
  const startDefaultDictationSession = async () => {
    setIsDictationSessionLoading(true);
    try {
      const response = await API.get("/dictation");
      onStartSessionSuccess(response);
    } catch (error) {
      onStartSessionFail(error);
    }
  }

  // 获取一个音频的听写历史
  const getDictationHistory = (audioUuid) => {
    setIsDictationSessionLoading(true);
    API
      .get('/history/'+audioUuid)
      .then(onStartSessionSuccess)
      .catch(onStartSessionFail);
  }

  // 收到成功 start session 响应
  const onStartSessionSuccess = (response) => {
    setDictationSession(response.data);
    setCurrentDictationIndex(
      response.data['last-dictation-pos'] >= response.data['segments-num']
      ? 0
      : response.data['last-dictation-pos']
    );
    setIsDictationSessionLoading(false);
    setIsDictationSessionFailed(false);
  }

  // 收到失败的 start session 响应
  const onStartSessionFail = (error) => {
    if (!error.response) {
      message.error("Network error. Please check your connection.");
    } else {
      message.error(error.response.data['error']);
    }
    setIsDictationSessionLoading(false);
    setIsDictationSessionFailed(true);
  }

  // 根据当前页面位置进行相应 session 初始化
  const location = useLocation();
  const { authHolder } = useContext(AuthContext);

  useEffect(() => {
    const initDictationSession = async () => {
      if (location.pathname === '/' && !authHolder.isLoggedIn) {
        await startGuestDictationSession();
      }
      if (location.pathname === '/play' && authHolder.isLoggedIn) {
        await startDefaultDictationSession();
      }
    }
    initDictationSession();
  }, [location]);

  const DictationSessionContextValue = useMemo(() => ({
    dictationSession,
    isDictationSessionLoading,
    isDictationSessionFailed,
    currentDictationIndex,
    isDictationAnswerDisplayed,
    setCurrentDictationIndex,
    startDictationSession,
    startDefaultDictationSession,
    startGuestDictationSession,
    getDictationHistory,
    setIsDictationAnswerDisplayed,
  }), [dictationSession, isDictationSessionLoading, isDictationSessionFailed, currentDictationIndex, isDictationAnswerDisplayed]);

  return (
    <DictationSessionContext.Provider value={DictationSessionContextValue}>
      {children}
    </DictationSessionContext.Provider>
  );
}

export default DictationSessionContextProvider;