import React, { useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { message, Row, Col, Button, Typography, Spin, Result, Space, Flex } from 'antd';
import { LeftOutlined, RightOutlined, VerticalRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { SettingContext } from '../../context/SettingContextProvider.jsx';
import { DictationSessionContext } from '../../context/DictationSessionContextProvider.jsx';
import { AudioContextContext } from "../../context/AudioContextContextProvider.jsx";

import AudioVisualizer from './AudioVisualizer.jsx';
import { DictationDisplay } from "./DictationInput.jsx";

const DictationHistory = () => {

  // Route path: /history-play/:audioUuid
  // API path: /history/{audio-uuid}
  const { audioUuid } = useParams();
  const { t, i18n } = useTranslation();

  const {
    dictationSession, 
    isDictationSessionLoading, 
    isDictationSessionFailed, 
    currentDictationIndex, 
    setCurrentDictationIndex, 
    getDictationHistory,
    setIsDictationAnswerDisplayed
  } = useContext(DictationSessionContext);
  const { isAudioContextResourceLoading } = useContext(AudioContextContext);

  useEffect(() => {
    setIsDictationAnswerDisplayed(true);
    return () => {
      setIsDictationAnswerDisplayed(false);
    };
  }, []);

  // 到上一个句子
  const backToPreviousSentence = () => {
    setCurrentDictationIndex(currentDictationIndex - 1);
  }

  // 到下一个句子
  const goToNextSentence = () => {
    setCurrentDictationIndex(currentDictationIndex + 1);
  }

  // 从头开始听
  const restartFromBeginning = () => {
    setCurrentDictationIndex(0);
  }

  return (
    <>
    {(isDictationSessionLoading || isAudioContextResourceLoading)
    ? <Flex justify="center" align="center">
        <Spin size="large" />
      </Flex>
    : <div>
      {isDictationSessionFailed
      ? <>
        <Result
          status="warning"
          title="Network failed. Please try again."
          extra={
            <Button type="primary" key="console" onClick={() => {getDictationHistory(audioUuid)}}>
              {t("重试")}
            </Button>
          }
        />
        </>
      : <>
          <Row>
            <Col
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <Space>
                <Button
                  type="text"
                  disabled={currentDictationIndex <= 0}
                  onClick={restartFromBeginning}
                >
                  <VerticalRightOutlined />
                </Button>
                <Button
                  type="text"
                  disabled={currentDictationIndex <= 0}
                  onClick={backToPreviousSentence}
                >
                  <LeftOutlined />
                </Button>
              </Space>
            </Col>
            <Col span={9}><div></div></Col>
            <Col 
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <Typography.Text>{(currentDictationIndex + 1)+ " / " + dictationSession["segments-num"]}</Typography.Text>
            </Col>
            <Col span={9}><div></div></Col>
            <Col 
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <Button
                type="text"
                disabled={currentDictationIndex >= dictationSession["segments-num"] - 1}
                onClick={goToNextSentence}
              >
                <RightOutlined />
              </Button>
            </Col>
          </Row>
          <Row style={{ height: "120px" }}>
            <Col span={6}></Col>
            <Col 
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <Typography.Title
                level={4}
                style={{
                  paddingTop: "10px",
                  zIndex: "100",
                }}
                strong
                ellipsis={{
                  rows: 3,
                  expandable: 'collapsible',
                }}
              >{dictationSession["sentences"][currentDictationIndex]['text']}</Typography.Title>
            </Col>
            <Col span={6}></Col>
          </Row>
          <Row>
            <Col span={8}><div></div></Col>
            <Col 
              span={8}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: "60px" }}
            >
              <AudioVisualizer />
            </Col>
            <Col span={8}><div></div></Col>
          </Row>
          <Row style={{ height: "200px"}}>
            <Col span={6}><div></div></Col>
            <Col 
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%' 
              }}
            >
              {dictationSession["sentences"][currentDictationIndex]['attemptText']
              ? <DictationDisplay
                  groundTruthStr={dictationSession['sentences'][currentDictationIndex]['text']}
                  inputStr={dictationSession['sentences'][currentDictationIndex]['attemptText']}
                />
              : <Typography.Text type='secondary'>{t("暂无听写记录")}</Typography.Text>
              }
            </Col>
            <Col span={6}><div></div></Col>
          </Row>
        </>}
      </div>}
    </>
  );
}

export default DictationHistory;