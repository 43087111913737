import react, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';
import { message, Row, Col, Button, Typography, Spin, Result, Space, Flex, Input } from 'antd';
import { LeftOutlined, RightOutlined, VerticalRightOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { SettingContext } from '../../context/SettingContextProvider.jsx';
import { DictationSessionContext } from '../../context/DictationSessionContextProvider.jsx';
import { AudioContextContext } from "../../context/AudioContextContextProvider.jsx";

import AudioVisualizer from './AudioVisualizer.jsx';
import SubmitDictationButton from './SubmitDictationButton.jsx';
import { DictationInput, DictationDisplay } from './DictationInput.jsx';

const Dictation = () => {

  // Route path: /play/:audioUuid
  const { audioUuid } = useParams();
  const { settingHolder, setSettingHolder } = useContext(SettingContext);
  const { isAudioContextResourceLoading } = useContext(AudioContextContext);

  /**
   * 全局 Session 信息
   * {
    "session-id": 2,
    "session-create-time": "2024-10-27T06:32:21.360163438"
    "audio-uuid": "c2cff0e3-318c-4c5e-bd4f-e16ab619260e",
    "audio-src": "http://echo-us-west.oss-us-west-1.aliyuncs.com/audio/c2cff0e3-318c-4c5e-bd4f-e16ab619260e.mp3?Expires=1730003541&OSSAccessKeyId=LTAI5tS66b6yK44Hm7Z6HS7q&Signature=DNYmMEWd1betrZarfVy7cjRZJhs%3D",
    "title": "Gettysburg Address",
    "duration": 192,
    "mimeType": "audio/mpeg",
    "access-level": 2,
    "segments-num": 19,
    "cover-img": "https://src",
    "last-dictation-pos": 0,
    "sentences": [
      {
        "sentenceId": 92,
        "audioUuid": "c2cff0e3-318c-4c5e-bd4f-e16ab619260e",
        "index": 1,
        "text": "Now listen to two students discussing the letter. ",
        "start": 10, // 单位: ms
        "end": 3760, // 单位: ms
      },
    ],
  }
  */
  const {
    dictationSession, 
    isDictationSessionLoading, 
    isDictationSessionFailed, 
    currentDictationIndex, 
    setCurrentDictationIndex, 
    startDictationSession,
    isDictationAnswerDisplayed,
    setIsDictationAnswerDisplayed
  } = useContext(DictationSessionContext);

  /**
   * 播放状态
   */

  // 重听上一个句子
  const backToPreviousSentence = () => {
    setCurrentDictationIndex(currentDictationIndex - 1);
    setIsDictationAnswerDisplayed(false);
  }

  // 跳过当前句子，进入下一个句子
  const goToNextSentence = () => {
    setCurrentDictationIndex(currentDictationIndex + 1);
    setIsDictationAnswerDisplayed(false);
  }

  // 从头开始听
  const restartFromBeginning = () => {
    setCurrentDictationIndex(0);
    setIsDictationAnswerDisplayed(false);
  }

  /**
   * Dictation Text
   */
  const [dictationText, setDictationText] = useState("");

  return (
    <>
    {(isDictationSessionLoading || isAudioContextResourceLoading)
    ? <Flex justify="center" align="center">
        <Spin size="large" />
      </Flex>
    : <div>
      {isDictationSessionFailed
      ? <>
        <Result
          status="warning"
          title="Network failed. Please try again."
          extra={
            <Button type="primary" key="console" onClick={() => {startDictationSession(audioUuid)}}>
              Retry
            </Button>
          }
        />
        </>
      : <>
          <Row>
            <Col 
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center', 
              }}
            >
              <Space>
                <Button
                  type="text"
                  disabled={currentDictationIndex <= 0}
                  onClick={restartFromBeginning}
                >
                  <VerticalRightOutlined />
                </Button>
                <Button
                  type="text"
                  disabled={currentDictationIndex <= 0}
                  onClick={backToPreviousSentence}
                >
                  <LeftOutlined />
                </Button>
              </Space>
            </Col>
            <Col span={9}><div></div></Col>
            <Col 
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center', 
              }}
            >
              <Typography.Text>{(currentDictationIndex + 1) + " / " + dictationSession["segments-num"]}</Typography.Text>
            </Col>
            <Col span={9}><div></div></Col>
            <Col 
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center', 
              }}
            >
              <Button
                type="text"
                disabled={currentDictationIndex >= dictationSession["segments-num"] - 1}
                onClick={goToNextSentence}
              >
                <RightOutlined />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={8}><div></div></Col>
            <Col 
              span={8}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: "60px" }}
            >
              <AudioVisualizer />
            </Col>
            <Col span={8}><div></div></Col>
          </Row>
          <Row style={{ height: "200px"}}>
            <Col span={6}><div></div></Col>
            <Col 
              span={12}
              style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100%' 
              }}
            >
            {isDictationAnswerDisplayed
            ? <DictationDisplay
                groundTruthStr={dictationSession['sentences'][currentDictationIndex]['text']}
                inputStr={dictationText}
              />
            : <DictationInput 
                setDictationText={setDictationText}
              />
            }
            </Col>
            <Col 
              span={6}
              style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100%' 
              }}
            >
              <div>
                {!isDictationAnswerDisplayed &&
                <SubmitDictationButton
                  dictationText={dictationText}
                  setIsDictationAnswerDisplayed={setIsDictationAnswerDisplayed}
                />}
              </div>
            </Col>
          </Row>
        </>
      }
      </div>}
    </>
  );
}

export default Dictation;
