import React, { useState, useEffect, useContext } from 'react';
import { message, Space, Spin, Flex, Input, Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import API from '../../api/api.js';
import { DictationSessionContext } from '../../context/DictationSessionContextProvider.jsx';

import './Vocabulary.css';

interface DictWord {
  wordId: number,
  word: string,
  phonetic: string,
  definition: string,
  translation: string,
  tag: string
}

interface VocabularyProps { 
  activeTab: string;
}

const LinesOfTranslation: React.FC<{ translation: string }> = ({ translation }) => {
  const entries = translation.split("\\n");
  const entriesFiltered = entries.filter((entry) => (
    entry.includes('n.') || 
    entry.includes('vt.') || 
    entry.includes('vi.') || 
    entry.includes('a.') || 
    entry.includes('adv.') ||
    entry.includes('abbr.') ||
    entry.includes('prep.') || 
    entry.includes('conj.') ||
    entry.includes('aux.') ||
    entry.includes('interj.') ||
    entry.includes('pron.') ||
    entry.includes('art.') ||
    entry.includes('num.')
  ));

  return (
    <div className='dict-entry-container'>
      {
        entriesFiltered.length !== 0 
        ? entriesFiltered.map(entry => <span key={entry}>{entry}</span>)
        : entries.map(entry => <span key={entry}>{entry}</span>)
      }
    </div>
  );
}

const DictWordCard: React.FC<{word: DictWord}> = ({ word }) => {
  return (
    <div className='dict-card'>
      <div className='dict-card-title'>
        <span className='dict-card__bold'>{word.word}</span>
        <span>{'['+ word.phonetic + ']'}</span>
      </div>
      <LinesOfTranslation translation={word.translation}/>
    </div>
  );
}

const Vocabulary: React.FC<VocabularyProps> = ({ activeTab }) => {

  const { t } = useTranslation();
  const { 
    dictationSession,
    isDictationSessionLoading,
    isDictationSessionFailed,
    currentDictationIndex,
    isDictationAnswerDisplayed
  } = useContext(DictationSessionContext);

  // 句中生词搜索
  const [isWordListLoading, setIsWordListLoading] = useState<Boolean>(false);
  const [wordList, setWordList] = useState<DictWord[]>([]);

  useEffect(() => {
    if (
      activeTab === 'vocabulary-tab' && isDictationAnswerDisplayed &&
      !isDictationSessionLoading && !isDictationSessionFailed 
    ) {
      setIsWordListLoading(true);
      API.get("/dict/keywords", {
        params: {
          'sentence': dictationSession['sentences'][currentDictationIndex]['text'],
          'level': 'cet4',
        }
      })
        .then((response) => {
          setWordList(response.data);
          setIsWordListLoading(false);
        })
        .catch((error) => {
          if (!error.response) {
            message.error("Network error. Please check your connection.");
          } else {
            message.error(error.response.data['error']);
          }
          setIsWordListLoading(false);
        });
    } else {
      setIsWordListLoading(false);
      setWordList([]);
    }
  }, [dictationSession, currentDictationIndex, isDictationAnswerDisplayed, activeTab]);

  // 单词搜索
  const [searchResult, setSearchResult] = useState<DictWord | null>(null);
  const [searchNoResult, setSearchNoResult] = useState<Boolean>(false);

  const onSearch = (value: string) => {
    setSearchResult(null);
    setSearchNoResult(false);
    API
      .get("/dict/search", {
        params: {
          'word': value,
        }
      })
      .then((response) => {
        if (response.data) {
          setSearchResult(response.data);
          setSearchNoResult(false);
        } else {
          setSearchResult(null);
          setSearchNoResult(true);
        }
      })
      .catch((error) => {
        if (!error.response) {
          message.error("Network error. Please check your connection.");
        } else {
          message.error(error.response.data['error']);
        }
      })
  };

  return (
    <div>
      <Space
        direction="vertical"
        size="small"
        style={{
          display: 'flex',
        }}
      >
        <Input.Search placeholder={t("输入需要查询的单词")} onSearch={onSearch} />
        {searchNoResult &&  <Typography.Text type='secondary'>{t("暂未搜索到单词")}</Typography.Text> }
        {searchResult && <DictWordCard word={searchResult}/>}
        <Divider></Divider>
        {
          isDictationAnswerDisplayed && isWordListLoading
          ? <Flex justify="center" align="center">
              <Spin />
            </Flex>
          : <div>
              {wordList.map((word, index) => 
                <DictWordCard word={word} key={'word-'+index} />
              )}
            </div>
        }
      </Space>
    </div>
  );
}

export default Vocabulary;